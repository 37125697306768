import * as React from 'react';

import {
  AboveTheFoldWrapProps,
  AudiStageLargeContent,
  AudiStageLargeProps,
} from './interfaces';
import {
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
} from '@audi/audi-ui-react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { useEffect, useMemo, useRef, useState } from 'react';

import AudiStageLargeDisclaimer from './audi-stage-large-disclaimer';
import AudiStageLargeImage from './audi-stage-large-image';
import AudiStageLargeInfoContent from './audi-stage-large-info-content';
import AudiStageLargeShader from './audi-stage-large-shader';
import AudiStageLargeVideo from './audi-stage-large-video';
import { EnumerableFootnote } from '@volkswagen-onehub/audi-footnote-reference-service';
import { parseImageAsset } from '../utils/parse-image-asset';
import { useWindowSize } from './hooks';

const { l: breakpointL, m: breakpointM } = audiLightTheme.breakpoints;

const StageWrap = styled.div<{
  bgColor: string;
  bottomHeightSoundToggleVideo: number;
  videoFeatureAppId: string;
}>`
  background-color: ${({ bgColor }): string => bgColor};
  margin: 0 auto;
  max-width: 1920px;
  position: relative;
  /* stylelint-disable */
  ${({
    videoFeatureAppId,
    bottomHeightSoundToggleVideo,
  }): FlattenSimpleInterpolation => css`
    @media (max-width: ${breakpointM - 1}px) {
      & #${videoFeatureAppId}-videoAppMuteButtonWrapper {
        bottom: ${bottomHeightSoundToggleVideo}px;
      }
    }
  `}/* stylelint-enable */
`;

const AboveTheFoldWrap = styled.div<AboveTheFoldWrapProps>`
  height: ${({ isStageOnTop }): string =>
    isStageOnTop ? 'calc(100vh - 70px)' : '100vh'};
  max-height: 1024px;
  position: relative;
`;

const AudiStageLarge: React.FC<AudiStageLargeProps> = (props) => {
  const {
    contentService,
    vueFormatterService,
    carlineService,
    referenceServiceManager,
    featureAppId,
    localeService,
    videoFeatureAppVersion,
    videoService,
    content: contentAsync,
    formattedConsumption,
    formattedEmission,
  } = props;
  const videoFeatureAppId = useMemo(
    () => `${featureAppId}-audi-feature-app-video`,
    [featureAppId],
  );

  const [content, setContent] = useState<AudiStageLargeContent>(
    contentAsync ? contentAsync : contentService.getContent(),
  );

  const videos = useMemo(() => content?.media.videos, [content?.media.videos]);
  const imageNarrowScreen = useMemo(
    () => content?.media.images.imageNarrowScreen,
    [content?.media.images.imageNarrowScreen],
  );

  const imageWideScreen = useMemo(
    () => content?.media.images.imageWideScreen,
    [content?.media.images.imageWideScreen],
  );

  const textElements = content ? content.textElements : {};

  const isStageOnTop = content?.appearance?.isStageOnTop;

  const theme =
    content?.appearance.theme === 'dark' ? audiDarkTheme : audiLightTheme;
  const bgColor = content?.appearance.theme === 'dark' ? '#000' : '#fff';

  const [assetDisclaimer, setAssetDisclaimer] = useState<string | undefined>(
    imageNarrowScreen?.assetDisclaimer,
  );
  const [imageAlt, setImageAlt] = useState<string | undefined>(
    imageNarrowScreen?.imageAlt,
  );
  const [hasDisclaimerInfo, setHasDisclaimerInfo] = useState<boolean>(
    formattedConsumption || formattedEmission ? true : false,
  );
  const { width } = useWindowSize();

  const [footnoteReferences, setFootnoteReferences] =
    useState<EnumerableFootnote[]>();

  const consumptionEmissionContainer = useMemo(() => {
    const eecContainer = content?.consumptionEmissionContainer;
    if (!eecContainer) {
      return null;
    }
    return eecContainer;
  }, [content?.consumptionEmissionContainer]);

  useEffect(() => {
    const contentChangeCallback = (): void => {
      setContent(contentService.getContent());
    };
    contentService.onContentChange(contentChangeCallback);
    return (): void => {
      // removeOnContentChange is not exported in the
      // interface in versions prior to 0.2.0
      // at the time being only

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      contentService.removeOnContentChange(contentChangeCallback);
    };
    // onMount Hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width < breakpointL) {
      let disclaimer = imageNarrowScreen?.assetDisclaimer;

      if (videos) {
        disclaimer = videos.videoNarrowScreen?.assetDisclaimer;
      }

      setAssetDisclaimer(disclaimer);
      setImageAlt(imageNarrowScreen?.imageAlt);
    } else {
      let disclaimer = imageWideScreen?.assetDisclaimer;

      if (videos) {
        disclaimer = videos.videoWideScreen?.assetDisclaimer;
      }

      setAssetDisclaimer(disclaimer);
      setImageAlt(imageWideScreen?.imageAlt);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, breakpointL]);

  useEffect(() => {
    if (!assetDisclaimer && !consumptionEmissionContainer) {
      setHasDisclaimerInfo(false);
    } else {
      setHasDisclaimerInfo(true);
    }
  }, [assetDisclaimer, consumptionEmissionContainer]);

  useEffect(() => {
    if (referenceServiceManager) {
      try {
        referenceServiceManager.registerCallback(
          (_footnotes: EnumerableFootnote[]) => {
            setFootnoteReferences(_footnotes);
          },
        );
      } catch (error) {
        console.warn(error);
      }
    }
    return (): void => {
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageUrlNarrow = useMemo(
    () => parseImageAsset(imageNarrowScreen?.image),
    [imageNarrowScreen?.image],
  );

  const imageUrlWide = useMemo(
    () => parseImageAsset(imageWideScreen?.image),
    [imageWideScreen?.image],
  );

  const messureRef = useRef<{
    readonly wrapper: HTMLDivElement;
    readonly button: HTMLDivElement;
  }>();

  const [videoSoundToggleBottom, setvideoSoundToggleBottom] = useState(0);

  useEffect(() => {
    if (
      videos &&
      messureRef &&
      messureRef.current &&
      messureRef.current.wrapper &&
      messureRef.current.button
    ) {
      const wrapper = messureRef.current.wrapper.offsetHeight;
      const button = messureRef.current.button.offsetTop;
      const distance = wrapper - button;

      if (width > breakpointM) {
        setvideoSoundToggleBottom(0);
      } else {
        setvideoSoundToggleBottom(distance);
      }
    }
  }, [width, messureRef, videos]);

  if (typeof content === 'undefined') {
    return <p>Stage Large Component</p>;
  }

  return (
    <ThemeProvider theme={theme}>
      <StageWrap
        {...{ bgColor }}
        bottomHeightSoundToggleVideo={videoSoundToggleBottom}
        data-audi-core-tracking-include="true"
        data-module="stage-large"
        videoFeatureAppId={videoFeatureAppId}
      >
        <AboveTheFoldWrap isStageOnTop={isStageOnTop}>
          {!videos && imageUrlWide && imageUrlNarrow && (
            <AudiStageLargeImage
              imageAlt={imageAlt}
              imageNarrow={imageUrlNarrow}
              imageWide={imageUrlWide}
            />
          )}

          {videos && (
            <AudiStageLargeVideo
              videoFeatureAppVersion={videoFeatureAppVersion}
              videoService={videoService}
              videofeatureAppId={videoFeatureAppId}
              videos={videos}
            />
          )}

          {(content?.appearance.topShader ||
            content?.appearance.bottomShader) &&
            !videos && (
              <AudiStageLargeShader
                bottomShader={content?.appearance.bottomShader}
                theme={content?.appearance.theme}
                topShader={content?.appearance.topShader}
              />
            )}
          {textElements && (
            <AudiStageLargeInfoContent
              alignRight={content?.appearance.alignRight}
              alignTop={content?.appearance.alignTop}
              footnoteReferences={footnoteReferences}
              isVideo={!!videos}
              ref={messureRef}
              referenceServiceManager={referenceServiceManager}
              textElements={textElements}
              theme={theme}
            />
          )}
        </AboveTheFoldWrap>
        {hasDisclaimerInfo && (
          <AudiStageLargeDisclaimer
            assetDisclaimer={assetDisclaimer}
            carlineService={carlineService}
            consumptionEmissionContainer={consumptionEmissionContainer}
            footnoteReferences={footnoteReferences}
            hideEec={content?.appearance.hideEec}
            localeService={localeService}
            referenceServiceManager={referenceServiceManager}
            theme={content?.appearance.theme}
            vueFormatterService={vueFormatterService}
            {...{ formattedConsumption, formattedEmission }}
          />
        )}
      </StageWrap>
    </ThemeProvider>
  );
};

export default AudiStageLarge;
