import * as React from 'react';

import { useEffect, useState } from 'react';

import { FootnoteReplacerProps } from './interfaces';
import styled from 'styled-components';

const FootnoteWrap = styled.span`
  & a {
    border-bottom: none;
    font-weight: normal;
  }
`;

type FootnoteReferenceExcerpt = {
  contextId: string;
  number: string;
};

const FootnoteReplacer: React.FC<FootnoteReplacerProps> = (props) => {
  const {
    content,
    referenceServiceManager,
    footnoteReferences: footnoteReferencesFromService,
  } = props;

  // find foonotes in text, multiple occurences of footnotes within the content string might be configured
  const findFootnoteIdsInContent: (content_: string) => string[] = (
    content_,
  ) => {
    const allFoundFootnotes: string[] = [];
    const referencePattern = new RegExp(`{(ft_(.*?))}|{(.*?)}`, 'g');
    if (content_ && content_ !== '') {
      let match: RegExpExecArray;
      while (null != (match = referencePattern.exec(content_))) {
        const footnoteKey = match[0]
          .replace('{', '')
          .replace('ft_', '')
          .replace('}', '');
        if (footnoteKey) {
          if (allFoundFootnotes.indexOf(footnoteKey) == -1) {
            allFoundFootnotes.push(footnoteKey);
          }
        }
      }
    }

    return allFoundFootnotes;
  };

  // setup a state for footnote management of local references
  const [footnoteReferencesInContent, setFootnoteReferencesInContent] =
    useState<string[]>(findFootnoteIdsInContent(content));

  // setup a state for footnote management of local references
  const [footnoteReferencesMerged, setFootnoteReferencesMerged] = useState<
    Map<string, FootnoteReferenceExcerpt>
  >(new Map<string, FootnoteReferenceExcerpt>());

  // setup a state for the final replaced content
  const [replacedContent, setReplacedContent] = useState<string>(content);

  // setup an effect which maps the local footnote references with the ones provided from footnote reference service
  useEffect(() => {
    if (footnoteReferencesFromService) {
      const preparedMap = new Map<string, FootnoteReferenceExcerpt>();
      footnoteReferencesInContent.forEach((footnoteKey) => {
        const referenceFromService = footnoteReferencesFromService.find(
          (x) => x.id == footnoteKey,
        );
        if (referenceFromService) {
          const footnoteValue: FootnoteReferenceExcerpt = {
            contextId: referenceFromService.contextID,
            number: '*',
          };
          if (
            typeof referenceFromService.number !== 'undefined' &&
            referenceFromService.number !== null
          ) {
            footnoteValue.number = referenceFromService.number.toString();
          }
          preparedMap.set(footnoteKey, footnoteValue);
        }
      });

      setFootnoteReferencesMerged(preparedMap);
    }
  }, [footnoteReferencesFromService, footnoteReferencesInContent]);

  useEffect(() => {
    if (
      referenceServiceManager &&
      footnoteReferencesInContent &&
      footnoteReferencesInContent.length > 0
    ) {
      referenceServiceManager.addFootnoteReferences(
        footnoteReferencesInContent,
      );
    }
  }, [footnoteReferencesInContent, referenceServiceManager]);

  // perform replacement of content
  useEffect(() => {
    if (content) {
      let replacedContent = content;
      footnoteReferencesInContent.forEach((footnoteKey) => {
        const mergedFootnoteReference = footnoteReferencesMerged.has(
          footnoteKey,
        )
          ? footnoteReferencesMerged.get(footnoteKey)
          : { contextId: footnoteKey, number: '*' };
        const replacePattern = new RegExp(
          `(<sup>)?({(ft_${footnoteKey})}|{${footnoteKey}})(</sup>)?`,
          'g',
        );
        const replaceWithString = `<a class='audi-j-footnote-reference' data-reference-id='${footnoteKey}' href='${
          '#' + mergedFootnoteReference.contextId
        }'><sup class='audi-footnote-anchor__text'>${
          mergedFootnoteReference.number
        }</sup></a>`;
        replacedContent = replacedContent.replace(
          replacePattern,
          replaceWithString,
        );
      });

      setReplacedContent(replacedContent);
    } else {
      setReplacedContent(content);
    }
  }, [footnoteReferencesInContent, footnoteReferencesMerged, content]);

  useEffect(() => {
    setFootnoteReferencesInContent(findFootnoteIdsInContent(content));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  if (
    typeof content === 'undefined' ||
    (typeof content === 'object' && content === null)
  ) {
    return null;
  }

  return (
    <FootnoteWrap
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{
        __html: replacedContent.replace(/(<? *script)/gi, 'illegalscript'),
      }}
    ></FootnoteWrap>
  );
};

export default FootnoteReplacer;
